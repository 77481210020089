import { Helmet } from 'react-helmet';

export type SeoProps = {
  url: string;
  locale: string;
  siteName: string;
  title: string;
  description: string;
  imageUrl: string;
};

export const Seo = ({ url, siteName, locale, title, description, imageUrl }: SeoProps) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="twitter:card" content="summary" />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        {imageUrl && <meta property="og:image" content={imageUrl} />}
        <meta property="og:image:height" content="630" />
      </Helmet>
    </>
  );
};
